import React from "react";
import "./about.css";
import about from "../../images/about.jpg";

function About() {
  return (
    <div className="about-container">
      <div className="about-image">
        <div className="image-frame">
          <img src={about} alt="My Image" />
        </div>
      </div>
      <div className="about-content">
        <h1>About Me</h1>
        <br />
        <p>
          Hi! My name is Tanisha Shibu Thomas . At the time of writing this, I'm
          a junior in high school and in the summer of 2022, I traveled from
          &nbsp;
          <i>
            <b>India to Italy</b>
          </i>{" "}
          &nbsp; to live alongside my sister for the span of two months. Milan
          never failed to keep me on my feet every single day, making me more
          and more curious about every corner that was yet undiscovered by my
          eyes. I've learnt a lot from my experiences while staying in Milan
          from getting lost on unfamiliar streets, to finally being able to talk
          to strangers confidently, to learning how to control my sugar
          addiction and much more. So I thought I could help other travelers
          better navigate themselves in this city with handy tips, and through
          the perspective of 16-year-old eyes.
          <br />
          <br /> No matter who you are, whether you’re a college student, going
          on a short business trip or going on vacation , I hope to make your
          experience in Milan the best it can be and help you make the most out
          of the city and inspire you to travel.
        </p>
      </div>
    </div>
  );
}

export default About;
