import React from "react";
import "./naviglio.css";

function Naviglio() {
  return (
    <div className="page-container backgroundNaviglio">
      <br />
      <br />
      <div className="page-header">
        <h1>NAVIGLIO</h1>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684348883/IMG_20220509_194902_vdvupj.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}></p>
        </div>

        <div className="page-text">
          <p>
            This area, I feel, is perfect in between the city center and
            residential area. Very tourist friendly, but it feels like you're in
            a small neighborhood. It is also the place for vintage stores &
            markets, so you can thrift away here if you choose to. I recommend
            coming here in the evening or even staying past sunset, cause you
            might not want to miss out on a pretty view and the ambiance at that
            time.
          </p>
          <p>
            Having <span className="pacifico-text"> Aperitivo </span> or in
            other words the “happy hour 2.0” is very common here. It usually
            takes place from 6pm onwards. The latin word aperire, hence the
            name, essentially means to “open up” the stomach and prep your
            palate before dinner. You get to choose a beverage of choice, from
            just a glass of wine to spritz, which will be accompanied with a
            side of appetizers- cheese, ham, prosciutto, olives and chips. It's
            brought about a traditional way to experience the elements of
            socializing and spending time with family in the Italian style.
          </p>
          <p>
            My sister and I decided to have an evening at
            <span className="pacifico-text"> Maya </span> to experience an
            unlimited buffet that served various types of pasta and sides that
            were more than delicious.
          </p>
          <p>
            When I visited Navigli, I made it a mission to try to visit the two
            major canals which are
            <span className="pacifico-text"> Naviglio Pavese </span>
            and <span className="pacifico-text"> Naviglio Grande </span>
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-text-tip">
          <p className="oswald-text">
            TRAVELTIP#4: If we're talking about transport, using trams & buses
            is a good way to see most of the city at a glance in a faster way.
            Metros are quick to take you even all the way to the outskirts in a
            few minutes.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684348883/IMG_20220509_190518_wxonn4.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}></p>
        </div>
        <div className="page-text">
          <p>
            Naviglio grande is lined with multiple restaurants that all serve
            aperitivo so the options at hand are endless. I suggest that you
            walk the way through all of them and see which one appeals to you
            the most and to get a gist of what each place is serving.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684348884/20220412_193507_ef1r7y.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}></p>
        </div>
        <div className="page-text">
          <p>
            We had gone to a mexican restaurant-
            <span className="pacifico-text"> La Catrina </span> and we enjoyed
            eating a little mexican food for a change. It had great spanish
            music playing in the back, and I had successfully shazam-ed each
            one. Great food. Pretty view, and good vibes.
          </p>
          <p>
            After indulging in an evening of aperitivo, it's dessert hour and
            THIS IS THE BEST GELATO I'VE HAD IN MILAN. The place I'm talking
            about is <span className="pacifico-text"> Gelato La Romana </span>.
            I don't know what they put in it to make it so good, but it will
            fulfill your sweet-tooth ache.
          </p>
          <p>
            In the middle of it all. A few steps from La Catrina, you will find
            the bakery- <span className="pacifico-text"> A 'vucciria </span> .
            It has the traditional Italian rice balls known as ‘arancini’,
            either stuffed with cheese, beef, or ham. Other than pizza, pasta
            and risotto, this should be on your bucket list of things to eat.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-text-tip">
          <p className="oswald-text">
            CULTURESHOCK#4: Being brought up in India and eating spicy food
            24/7, I can say that indulging in Italian cuisine made me appreciate
            the heavily seasoned food in India a lot more. At home, when we
            cooked up some homemade pasta or ordered it out, it was always rich
            in spice and flavor and after coming here, it caught me by surprise
            by how underseasoned it can be at most places.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Naviglio;
