import Posts from "../../components/posts/Posts";
import BannerSlider from "../../components/slider/Slider";
import "./homepage.css";

export default function Homepage() {
  const data = [
    {
      image:
        "https://res.cloudinary.com/dl695mmqm/image/upload/v1685581712/20220402_134004_fjacxu.jpg",
      title: "Milan",
      description:
        "Milan, as everyone would know it, is the  fashion capital. It is true. You would see people dressed up neatly even in the grocery stores. People carry it with their identity. The way I see Milan now. ",
      link: "/milan",
    },
    {
      image:
        "https://res.cloudinary.com/dl695mmqm/image/upload/v1685581713/IMG_20220510_095725_ndqubk.jpg",
      title: "Lake Como",
      description:
        "If you’ve come all the way to Milan, you must go to this place. For me it was almost like stepping into my laptop's wallpaper or even avatar world. ",
      link: "/lake-como",
    },
  ];

  return (
    <div>
      <BannerSlider />
      <div className="home homepage-cards">
        <Posts data={data} />
      </div>
    </div>
  );
}
