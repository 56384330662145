import React from "react";
import "./port-romana.css";

function PortRomana() {
  return (
    <div className="page-container backgroundPortRomana">
      <br />
      <br />
      <div className="page-header">
        <h1>PORT ROMANA</h1>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684350030/20220411_193626_bysnnd.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}></p>
        </div>

        <div className="page-text">
          <p>
            I found myself spending a lot of my time here. Whether it was to do
            a grocery run or simply walking around to get to places. It has
            everything you need. If you want to eat anything, I mean like sushi,
            panini, pasta or even fried chicken, there is a restaurant there for
            it. Grocery store. It's there from cafes to turkish kebap. All over.
            Pharmacy, bank, Mcdonalds, vintage clothing stores, apartment
            facilities, furniture shops and the list goes on. It's there. Mostly
            everything is easily accessible and right around the corner.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-text-tip">
          <p className="oswald-text">
            TRAVELTIP#5: Make sure to remember to book your trip around the time
            when the weather is nice because the last thing you want to do is
            come all the way to just melt in the blazing sun or freeze in the
            bone chilling cold when you just want to walk around the city.
            Weather can vary from hour to hour, so make sure you check the daily
            forecast in case you need a raincoat later in the day.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684350033/20220418_202436_wmwznn.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Pizza am</p>
        </div>
        <div className="page-text">
          <p>
            I loved walking around this area. I loved the fact that it was away
            from tourist packed areas and had a pleasant ambiance.
          </p>
          <p>
            My sister states that this is the best pizzeria in Milan and I
            couldn't have agreed more. This place is called
            <span className="pacifico-text"> Pizza AM </span>. Thin crust, tangy
            sauce and fresh mozzarella all came together to make the most
            addictive bite.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-text-tip">
          <p className="oswald-text">
            CULUTRESHOCK#5: If you enter a restaurant craving a pepperoni pizza
            and decide to order one, the waiter is going to be as confused as
            you are about this fact. YES. Italians normally don't put meat on
            pizza, while at times you will find places that do serve pizza with
            meats such as prosciutto. The closest you can get to a pepperoni
            pizza is ordering the ‘diavola’.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684350026/20220413_115039_dvpx9j.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Bar d’este</p>
        </div>
        <div className="page-text">
          <p>
            But if your palette craves for more pasta than pizza, I suggest
            heading to <span className="pacifico-text"> Casa Tua Osteria </span>{" "}
            or <span className="pacifico-text"> Giulio Pane E Ojo </span>.
          </p>
          <p>
            After a month of purely consuming croissants and panini’s, and
            missing my home food, my sister and I thought it would be a nice
            change and fun to eat a meal at one of the Indian restaurants in
            Crocetta. A plate full of butter chicken, tandoori chicken and naan
            never sounded better.
          </p>
          <p>
            If you're a student who needs to get work done or just needs a break
            from your apartment or even if you just want a place to chill out, a
            few study cafes which I found really nice were
            <span className="pacifico-text"> Red Cafe </span> or
            <span className="pacifico-text"> Home Cafe </span> . My sister and I
            would often go there to take a break and to do work outside of her
            apartment.
          </p>
          <p>
            A cute cafe that my sister used to stop by now and then was
            <span className="pacifico-text"> Bar d’este </span> and the
            chocolate croissant was a favorite and something I found myself
            always looking forward to having in any eatery we stepped into.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684350022/20220410_183936_yyyr8j.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Parco ravizza</p>
        </div>
        <div className="page-text">
          <p>
            There was one place in particular that I always resorted to going to
            when I stepped out most days. Whether it was to finish a few
            chapters of a novel, go for a small jog or lay in the grass, it was
            my comfort place. Parco
            <span className="pacifico-text"> Ravizza </span> has a serene beauty
            and a nourishing enchantment that never failed to put a smile on my
            face.
          </p>
          <p>
            In May, there was a group called IL PAGANTE who put on a short
            performance at this park. It seemed to me that this artist is well
            known as the whole crowd of college kids were screaming out the
            lyrics at the top of their lungs. I was in awe. Not only by the
            music blaring through the speakers, but by the energy present that
            day. These people knew how to party. We joined in and I had my first
            ever park concert experience. I begged my sister to sit on her
            shoulders and before I knew it, I could see the swarm of college
            students ahead of me, chanting out the lyrics.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-text-tip">
          <p className="oswald-text">
            CULUTRESHOCK#6: You also won't see locals drink cappuccino past 11
            here at all. Most people say it has something to do with upsetting
            your digestion or that milk is mostly associated with the start of
            the day and is to only to be had in the morning.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PortRomana;
