import HeroSlider, { Slide, Overlay } from "hero-slider";
import Title from "../Title/index";
import Wrapper from "../Wrapper/index";
import Subtitle from "../Subtitle/index";

const images = [
  "https://res.cloudinary.com/dl695mmqm/image/upload/v1683967232/20220411_191731_gxklbo.jpg",
  "https://res.cloudinary.com/dl695mmqm/image/upload/v1683967592/IMG_20220503_184436_aasgry.jpg",
  "https://res.cloudinary.com/dl695mmqm/image/upload/v1683967593/duomo_wvnnlo.jpg",
  "https://res.cloudinary.com/dl695mmqm/image/upload/v1683967593/IMG_20220430_183958_avnl2a.jpg",
  "https://res.cloudinary.com/dl695mmqm/image/upload/v1683967593/IMG_20220513_210934_ltvhh5.jpg",
  "https://res.cloudinary.com/dl695mmqm/image/upload/v1683967602/IMG_20220510_114248_ruzpu7.jpg",
];

export default function BannerSLider() {
  return (
    <HeroSlider
      height={"100vh"}
      autoplay
      controller={{
        initialSlide: 1,
        slidingDuration: 500,
        slidingDelay: 100,
      }}
    >
      <Overlay>
        <Wrapper>
          <Title>Travelling with Tan</Title>
          <Subtitle>
            Not your average, actually very average travel blog
          </Subtitle>
        </Wrapper>
      </Overlay>
      {images.map((image, index) => (
        <Slide
          key={index}
          shouldRenderMask
          background={{
            backgroundImageSrc: image,
          }}
        />
      ))}
    </HeroSlider>
  );
}
