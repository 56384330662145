import React from "react";
import "./duomo.css";

function Duomo() {
  return (
    <div className="page-container backgroundDuomo">
      <br />
      <br />
      <div className="page-header">
        <h1>DUOMO</h1>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1682880581/duomo_di_milano_cathedral_ettf1z.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Duomo di milano cathedral</p>
        </div>

        <div className="page-text">
          <p>
            Wherever you travel, there is always that place in a city that
            appeals to all tourists. Some might even say that they come all the
            way just to see it. It's a place that is always crowded. Everyone is
            taking pictures. All faces are in awe. This place in Milan is the
            Duomo. The <span className="pacifico-text">Cathedral</span> to be
            specific. It's what you’d expect it to be.
          </p>
          <p>
            Going inside the cathedral is also something I recommend doing,
            because if you’re going to want to visit a church, it’s this one.
          </p>
          <p>
            You can also climb 250 unforgivable stairs all the way to the top,
            to reach the terrace where you can catch the view of the city, along
            with crowds of tourists and attention seeking pigeons below you.
          </p>
          <p>
            Try to do so in the early morning and on a weekday when you can not
            only observe the sunrise but also avoid standing amidst large
            chaotic crowds & waiting in long lines for what will seem like the
            whole day.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1682882442/20220404_141511_kfozaf.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Galleria</p>
        </div>
        <div className="page-text">
          <p>
            Right near the church, you will find the
            <span className="pacifico-text">Galleria</span>. It was the closest
            I got to being in a “Met Gala” like environment. What can I say, it
            was rich in golden highlights, crowned by designer shops and filled
            with an ancient yet modern feel.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-text-tip">
          <p className="oswald-text">
            SAFETY TIP: AVOID making conversation, giving any personal details,
            and even receiving anything from a stranger at all costs. This will
            give pickpockets a chance to steal your belongings easily. In
            crowded areas, restaurants, & while on public transport facilities
            especially, if you're carrying a sling bag or any bag for that
            matter, AT ALL TIMES, ensure that it is in your view, otherwise
            you're at a risk of your cash, phone or even jewelry getting
            pickpocketed.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1682882433/20220402_134255_js2lfh.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}></p>
        </div>
        <div className="page-text">
          <p>
            I wouldn't recommend eating here a lot as it is the central area,
            food will be expensive, and definitely not worth the price. Most
            eateries are tourist traps because again it's where the crowd hangs
            out. They know that tourists and newcomers don't necessarily know
            what true Italian food tastes like, so in a way you could get fooled
            into eating anything they serve. Any restaurant with large photos of
            the food and one that has a menu written in english, is a trap.
          </p>
          <p>
            Make sure to carry your own water bottle wherever you go as buying
            water bottles outside is quite expensive. There will also be water
            fountains for you to refill them around the city!
          </p>
          <p>
            However, If you do decide to eat or grab a bite in Duomo, I would
            recommend going to
            <span className="pacifico-text">Fratelli la bufala</span>
            or
            <span className="pacifico-text">Fresco or Antica Pizza Fritta</span>
            for a slice of pizza, And for a scoop of some sweet gelato, head to
            <span className="pacifico-text">Grom</span>.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-text-tip">
          <p className="oswald-text">
            Travel tip#1: When you come all the way to Italy, you hope to eat
            good gelato. The places you should go to to eat quality gelato is
            where the ice cream isn't mounted up, and instead is closed and
            covered. You know then that the ice cream is taken care of and is
            truly Italian.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1682882439/20220404_155539_gkyagl.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}></p>
        </div>
        <div className="page-text">
          <p>
            I remember on my first day in Milan, eagerly wanting to eat gelato
            immediately, but my sister, who has been living there for more than
            a year, refused to take me to any of the outlets which had mounds of
            gelato stacked up high and grabbing the attention of tourist eyes.
            She knew better to divert me towards the most promising one near her
            apartment, and I’m glad she did.
          </p>
          <p>
            If we're also talking about baristas and bakeries, you must not miss
            going to <span className="pacifico-text">Princi il forno</span>. If
            you love tiramisu, chocolate brioche, pastries, and breads, then
            this is the place for you.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1682882440/20220404_140055_a8ivw2.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Cesarino</p>
        </div>
        <div className="page-text">
          <p>
            You can also head to <span className="pacifico-text">Cesarino</span>
            to grab a sandwich. They make their bread fresh and you get it
            almost right out of the open oven with fresh mozzarella and meat, &
            vegetarian options as well.
          </p>
          <p>
            And to get all that food down your system, or if you're just thirsty
            and want something refreshing,{" "}
            <span className="pacifico-text">Frankly</span> boba tea is the spot.
            (Rec: iced caramel latte macchiato with boba pearls)
          </p>
          <p>
            I highly suggest just exploring and walking around Duomo as along
            the way, you will be accompanied by live music, a population of
            hungry pigeons, and a few street performances to catch your eye. The
            architecture. The ambiance. The life. It will be a lot to take in at
            first but the beauty of the city is something you can never get
            over.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684345248/IMG_20220427_170635_i7qtjy.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Sempione park</p>
        </div>
        <div className="page-text">
          <p>
            The walk from Duomo to
            <span className="pacifico-text">Sempione Park </span>
            is one to remember. It was the first thing we did the day after
            arriving, and I remember finding it so hard to take in what was in
            front of me, as it was quite overwhelming and seemed very unreal. On
            the walk, we stopped to watch people carving out animals using just
            sand and pallet knives, people spray painting canvases, and
            strumming along their guitar, singing a song.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1682882437/20220402_140916_cflebn.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Sforzesco castle</p>
        </div>
        <div className="page-text">
          <p>
            On the street parallel to the big Gucci sign, you can walk forward,
            towards the
            <span className="pacifico-text">Sforzesco Castello</span>. The entry
            is for free, but if you do enjoy engaging with more of the art and
            its history, going into those specific museums is a good idea but it
            will require you to pay an entry fee of €8 - €10. Ahead of the
            Castello,<span className="pacifico-text">Sempione Park</span> will
            await you. Walking into the park I was greeted by acres of greenery
            shared with blurs of adolescents throwing frisbees into the air and
            drummers rolling in their beats which echo into the ears of eager
            listeners.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1682882435/20220402_144910_ulfbhi.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Arco della pace</p>
        </div>
        <div className="page-text">
          <p>
            This is also the safest place to go cycling (Bikeme) and for using
            the scooters (Void, Lime or Helbiz) as you will have a lot of room
            to work with and there is no risk of getting run over by an innocent
            vehicle. You can end your walk at the
            <span className="pacifico-text"> Arco Della Pace </span> which lies
            after the park. It is truly beautiful and a perfect way to end the
            day.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684346496/IMG_20220519_162010_n2cehd.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Basilica di Sant'Ambrogio church</p>
        </div>
        <div className="page-text">
          <p>
            The <span className="pacifico-text"> Basilica Church </span>
            reminded me of how stunning and beautiful the architecture is in
            Milan. It's magical when you see something in person, especially
            when you've only been looking at it through a screen and suddenly
            it's right in front of you. I was pleased to see that most of the
            structures in Italy are only retouched upon and not modernized to
            match the current standard of architecture. Its antique and ancient
            nature at the end was what appealed to me the most.
          </p>
          <p>
            I feel like Milan wouldn't be the same place if not for that
            decision to preserve their heritage.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-text-tip">
          <p className="oswald-text">
            CULURESHOCK#1: I was extremely shocked to see people, who were most
            probably locals, eating a pizza with a fork and knife. It is, as far
            as i know, because the crust is extremely light and thin, so to
            avoid making a mess while eating, Italians find it easier to consume
            it like this.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Duomo;
