import React from "react";
import "./prettiest-place-in-milan.css";

function PrettiestPlaceInMillan() {
  return (
    <div className="page-container backgroundPrettiestPlaceInMilan">
      <br />
      <br />
      <div className="page-header">
        <h1>PRETTIEST PLACE IN MILAN</h1>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684352741/Screenshot_20230312-115913_Instagram_keifqe.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}></p>
        </div>

        <div className="page-text">
          <p>
            May i present to you ~
            <span className="pacifico-text"> Parco Vettabbia </span> ~ all the
            way in Porto di Mare.
          </p>
          <p>
            During my first week in Milan, my parents and I stayed up in Porto
            di Mare. The minute we arrived, I remember that all I was thinking
            about was wanting to cycle freely in the parks and on the streets,
            because this is the place to do so and it was an activity I was most
            excited to do.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684352741/Screenshot_20230312-120008_Instagram_qjysfb.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}></p>
        </div>
        <div className="page-text">
          <p>
            One day, my mom and I decided to go to get some fresh air, walk
            around and explore the neighborhood. While doing so, we came across
            a row of Bikeme cycles and I instantly knew I had to be riding on
            one of them. So after unlocking it from the app, I hopped on and
            rode on, towards any place I thought looked beautiful. A couple of
            minutes later, I found myself at PARCO VETTABBIA. The minute I set
            my eyes on it, I knew I wasn't going to be turning back. Without a
            second thought, I rode on forward with columns of an endless line of
            the prettiest trees I've ever seen in my life, along the way.
            Pedaling & more pedaling, and I drowned in acres of land holding
            small lakes, trees, and endless miles just green. When I say that
            there was no end to the path I was cycling on, I can guarantee it is
            not an exaggeration. It is so massive, that you could potentially
            get lost if you wandered off a bit too far.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrettiestPlaceInMillan;
