import React from "react";
import { useHistory } from "react-router-dom";

import "./homepage-cards.css";

function HomePageCards(props) {
  const history = useHistory();

  const onCardClick = () => {
    window.scrollTo(0, 0);
    history.push(props.link);
  };

  return (
    <div className="card-container" onClick={onCardClick}>
      <div className="card" style={{ backgroundImage: props.image }}>
        <img src={props.image} alt="My Image" />
        <div className="title">{props.title}</div>
        <div className="description">{props.description}</div>
      </div>
    </div>
  );
}

export default HomePageCards;
