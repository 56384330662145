import React from "react";
import Posts from "../../posts/Posts";
import "./lake-como.css";

function LakeComo() {
  const data = [
    {
      image:
        "https://res.cloudinary.com/dl695mmqm/image/upload/v1685164345/IMG_20220510_114116_krbrbf.jpg",
      title: "Bellagio",
      link: "/bellagio",
    },
    {
      image:
        "https://res.cloudinary.com/dl695mmqm/image/upload/v1685164353/IMG_20220510_182631_l311cd.jpg",
      title: "Varenna",
      link: "/varenna",
    },
  ];

  return (
    <div className="page-container backgroundLakeComo">
      <br />
      <br />
      <div className="page-header">
        <h1>LAKE COMO</h1>
      </div>
      <div className="page-content">
        <div className="page-text-tip">
          <p className="oswald-text">
            TRAVELTIP#1: When booking your train from Milan, make sure to get
            your tickets on an app called OMIO. It makes the whole process
            simpler and it is better to book in advance on this site.
            <br /> I would also recommend carrying a small suitcase and packing
            light as it will make moving into the train much more easy and
            comfortable. I say this from a dark experience of carrying two,
            quite heavy suitcases through and up & down the station and I can
            say that it was not the best experience.
            <br /> Make sure that you are at the platform at least 20 minutes
            before the train to lower the risk of missing the train as it only
            waits for 1-2 minutes before it leaves.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1685581713/IMG_20220510_095725_ndqubk.jpg"
              alt="My Image"
            />
          </div>
        </div>
        <div className="page-text">
          <p>
            If you’ve come all the way to Milan, you must go to this place. For
            me it was almost like stepping into my laptop's wallpaper or even
            avatar world.
          </p>
          <p>
            My sister and I went on a day trip which I think should be enough
            time to see 2 places thoroughly, keeping in mind that you should be
            fast on your feet and start the day early.
          </p>
          <p>
            We decided to cover both
            <span className="pacifico-text">Varenna & Bellagio</span> on this
            day. <span className="pacifico-text">VMenagio</span>
            is also supposed to be worth the visit, but we chose to skip it,
            taking account of the time we had and how much we wanted to do in a
            day.
          </p>
          <p>
            From Milan Centrale Station, we reached VARENNA and sat for
            breakfast at a restaurant facing the lake-
            <span className="pacifico-text">Bar Molo</span>. I definitely
            recommend getting the chocolate brioche here. You can head over here
            while you wait for your ferry to arrive.
          </p>
        </div>
      </div>
      <h2>Places to Visit in Lake Como </h2>
      <Posts data={data} />
    </div>
  );
}

export default LakeComo;
