import React from "react";
import "./brera.css";

function Brera() {
  return (
    <div className="page-container backgroundBrera">
      <br />
      <br />
      <div className="page-header">
        <h1>BRERA</h1>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684347051/IMG_20220518_190037_o74o4q.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>
            Accademia di Brera (brera academy)
          </p>
        </div>

        <div className="page-text">
          <p>
            Having a mental note of all the places to go to and see, initially
            meant that I would be working my legs to soreness, but little did I
            know as I arrived that everything was all held in a single square-
            <span className="pacifico-text">Accademia Di Brera</span>
            and that all the landmarks are just a few steps away. A Tourist’s
            dream? Definitely.
          </p>
          <p>
            So you're interested in art and its history? You have
            <span className="pacifico-text">Pinacoteca Di Brera</span>. The art
            is exquisite and like candy to the eye. It's a lot to cover as I
            found myself getting drowned in its tranquility and beauty, jumping
            from one room into the other, feeling like it was some sort of maze.
          </p>
          <p>
            Want to see a variety of exquisite plants and vegetation grown in a
            blossoming garden?{" "}
            <span className="pacifico-text">Orto Botanico Di Brera</span>Orto
            Botanico Di Brera is the place. The flowers, the trees, the acres of
            green. It is the dream garden. I found it helpful to see the names
            they went by in case I'd one day wake up and get a sudden urge to
            grow plants in my house.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-text-tip">
          <p className="oswald-text">
            TRAVELTIP#2: I would highly suggest checking what time each place
            opens and closes, alongwith pre-booking a slot. Most museums and
            historical sites have ticket offices that close an hour before the
            place shuts down for the day, so it is better to arrive a couple
            hours before it closes to give yourself ample amount of time to go
            around the place.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684347052/IMG_20220512_170858_zigk8p.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Orto Botanico Di Brera</p>
        </div>
        <div className="page-text">
          <p>
            Eager to check out telescopes and know more about the history of
            this observatory and astronomy itself?
            <span className="pacifico-text">
              The Brera Astronomy Observatory
            </span>
            should satisfy your curiosity.
          </p>
          <p>
            Just walking through
            <span className="pacifico-text">The Brera District,</span> I would
            hear jazz tunes transform the energy and create a rare & cherishable
            atmosphere as the sun goes down, and the bright colors of the
            buildings fade into the night.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684347052/IMG_20220518_191441_ivg5ii.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}></p>
        </div>
        <div className="page-text">
          {/* <div className="page-text-tip"> */}
          <p className="oswald-text">
            CULTURESHOCK#2 : The timings in Milan for most restaurants work
            differently than what you're probably used to. They are open from
            morning at 7am for breakfast and coffee, lunch at 12pm till 3. Then
            reopen for dinner at 7:30/8pm till late into the night. Most cafes
            are open till around 6 or in a few cases 8:30pm.
          </p>
          {/* </div> */}
        </div>
      </div>
      {/* <div className="page-content">
        <div className="page-text-tip">
          <p className="oswald-text">
            CULTURESHOCK#2 : The timings in Milan for most restaurants work
            differently than what you're probably used to. They are open from
            morning at 7am for breakfast and coffee, lunch at 12pm till 3. Then
            reopen for dinner at 7:30/8pm till late into the night. Most cafes
            are open till around 6 or in a few cases 8:30pm.
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default Brera;
