import React from "react";
import "./chinatown.css";

function Chinatown() {
  return (
    <div className="page-container backgroundChainTown">
      <br />
      <br />
      <div className="page-header">
        <h1>CHINATOWN</h1>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684347829/20220409_150611_btvmsy.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}></p>
        </div>

        <div className="page-text">
          <p>
            This area is a little China, so you're essentially hitting two birds
            with one stone.
          </p>
          <p>
            A day maybe is enough time to explore the area and the bundle of
            tiny markets filled with clothing/accessories/shoe stores where they
            can be more expensive than shops in Duomo or available for a steal.
            If you find it hard to figure out where to eat, you can just follow
            your nose and eyes, and you won’t go wrong.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-text-tip">
          <p className="oswald-text">
            TRAVELTIP#3: Knowing that Milan is a big city, in terms of
            popularity and tourism, I went into the city with the presumption
            that I could get around with knowing only english, But no matter
            where you are, whether it be a waiter in a pizzeria or the tram
            driver, being able to make basic conversation or speak basic phrases
            will really come in handy to get your way around the city easily, to
            read menus and even ask the cashier how their day was.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1684347831/20220409_150814_zqyrok.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}></p>
        </div>
        <div className="page-text">
          <p>
            You should definitely make it a mission to eat at
            <span className="pacifico-text"> Tang Gourmet. </span>
            The food is completely worth it. I definitely recommend ordering the
            chili garlic glass noodles, the bao’s and xiao long bao(soup
            dumplings). You also will have your own bottle of sriracha, so go
            nose-sniffling crazy.
          </p>
          <p>
            After that you can head on over to
            <span className="pacifico-text"> QQ TEA </span> for some teriyaki
            octopus balls (which I was fooled into eating thinking it was
            chicken) and refreshing bubble tea - even though you just told your
            mom you're extremely full from lunch a few minutes ago.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-text-tip">
          <p className="oswald-text">
            CULTURE SHOCK#3: If you go into a restaurant with the hopes of
            merely just using the toilet, or just to grab a seat, you might want
            to re-think that. The workers there don't allow this unless you're
            buying one of their products. It isn’t illegal but it is frowned
            upon.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Chinatown;
