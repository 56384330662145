import React, { useState } from "react";
import "./contact.css";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`Name: ${name}, Email: ${email}, Message: ${message}`);
    // You can add your own logic here to submit the form data to a server
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <h1 style={{ color: "rgb(15, 53, 124);" }}>Let's Connect</h1>
      <input
        className="input-box"
        style={{ marginTop: "5%" }}
        type="text"
        id="name"
        value={name}
        placeholder="Name"
        onChange={(event) => setName(event.target.value)}
      />

      <input
        className="input-box"
        type="email"
        id="email"
        value={email}
        placeholder="Email"
        onChange={(event) => setEmail(event.target.value)}
      />

      <textarea
        className="text-area-box"
        style={{
          border: "none",
          backgroundColor: "transparent",
          borderBottom: "2px solid rgb(15, 53, 124)",
          maxHeight: "60px",
          minHeight: "60px",
          maxWidth: "100%",
          minWidth: "100%",
        }}
        id="message"
        value={message}
        placeholder="Messsage"
        onChange={(event) => setMessage(event.target.value)}
      ></textarea>

      <button className="connect-button" type="submit">
        Connect
      </button>
    </form>
  );
}

export default Contact;
