import React from "react";
import "./varenna.css";

function Varenna() {
  return (
    <div className="page-container backgroundVarenna">
      <br />
      <br />
      <div className="page-header">
        <h1>VARENNA</h1>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1685164353/IMG_20220510_182631_l311cd.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Piazza San Giorgio</p>
        </div>
        <div className="page-text">
          <p>
            You can walk up stairs through an alley to reach
            <span className="pacifico-text">
              Piazza San Giorgio and Comune di Varenna
            </span>
            . It's almost like the duomo center of Varenna, but with fewer
            pigeons. Just down the street you can then head to
            <span className="pacifico-text">
              Villa Cipressi Botanical Garden or Villa monastero.
            </span>
            The price per person differs depending on your age as it’ll be € 8
            for an adult and € 3 for 12-24 year olds.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1685164355/IMG_20220510_165652_cpkx0d.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>villa Cipressi Botanical gardens</p>
        </div>
        <div className="page-text">
          <p>
            My sister and I went to the villa Cipressi Botanical gardens. The
            pathway in garden did lead us to a balcony with basket chairs that
            had a view of the mountains that stood in front us, and to give our
            legs a break, we gladly sat down for a few moments to take in all
            the beauty this town had and enjoy the present daylight streaming
            in, lighting up Lake Como’s enchantment. One thing I would recommend
            doing, which I wish I did in hindsight, is hiking, especially if you
            love nature.
          </p>
          <p>
            I'm not very familiar with what the best routes are, but I suggest
            that you get the app ALL TRAILS- it has information on how to go
            about the route, various trails to go on, and interesting stops on
            the way.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1685164344/IMG_20220510_094723_haovaj.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Riva Grande</p>
        </div>
        <div className="page-text">
          <p>
            With the golden hour on the rise, you can head to
            <span className="pacifico-text">Riva Grande</span> (which is right
            near the ferry station) and take a seat at the cobblestone-like
            slope by the edge of the lake and watch the sunset fall on the
            surface of the water.
          </p>
          <p>
            I can definitely say in hindsight that Going to Lake Como was a
            great decision.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Varenna;
