import React from "react";
import Posts from "../../posts/Posts";
import "./milan.css";

function Milan() {
  const data = [
    {
      image:
        "https://res.cloudinary.com/dl695mmqm/image/upload/v1682880581/duomo_di_milano_cathedral_ettf1z.jpg",
      title: "Duomo",
      link: "/duomo",
    },
    {
      image:
        "https://res.cloudinary.com/dl695mmqm/image/upload/v1684347052/IMG_20220518_191521_x5xsvr.jpg",
      title: "Brera",
      link: "/brera",
    },
    {
      image:
        "https://res.cloudinary.com/dl695mmqm/image/upload/v1684347829/20220409_150611_btvmsy.jpg",
      title: "ChinaTown",
      link: "/chinatown",
    },
    {
      image:
        "https://res.cloudinary.com/dl695mmqm/image/upload/v1684348883/IMG_20220509_194902_vdvupj.jpg",
      title: "Naviglio",
      link: "/naviglio",
    },
    {
      image:
        "https://res.cloudinary.com/dl695mmqm/image/upload/v1684350030/20220411_193626_bysnnd.jpg",
      title: "Port Romana",
      link: "/port-romana",
    },
    {
      image:
        "https://res.cloudinary.com/dl695mmqm/image/upload/v1684352741/Screenshot_20230312-120008_Instagram_qjysfb.jpg",
      title: "The Prettiest Place in Milan",
      link: "/prettiest-place-in-milan",
    },
  ];

  return (
    <div className="page-container backgroundMilan">
      <br />
      <br />
      <div className="page-header">
        <h1>MILAN</h1>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1685581712/20220402_134004_fjacxu.jpg"
              alt="My Image"
            />
          </div>
        </div>
        <div className="page-text">
          <p>
            Milan, as everyone would know it, is the fashion capital. It is
            true. You would see people dressed up neatly even in the grocery
            stores. People carry it with their identity. The way I see Milan
            now. It is no more just a hub of fashion domination, but a place
            where you go back in time, a place like home, a place where people
            take pizza fork-and-knife seriously, a place where there is
            something for everyone whether you’re in for the historic
            architecture or to aimlessly ROME the footprint-stained streets. You
            could be dazed out in awe of the ancient bodies of artistic work and
            the next minute be sipping aperol by the Naviglio grande on a breezy
            evening.
          </p>
          <p>
            I wanted to cover areas that wouldn’t be the first thing that pops
            up when you search “best places to visit in milan”. There are always
            these small hidden gems in every city which could go unnoticed.
          </p>
          <p>
            I’ve divided this giant pizza you can call milan into 6 pieces of
            duomo, Brera, Chinatown, Naviglio, Chinatown, Porta Romana, and in
            my opinion, THE PRETTIEST PLACE IN MILAN Each has their own toppings
            and flavor. Some can taste familiar and some strange, but I can
            assure you that each bite will satiate your craving for adventure,
            mystery and exploration.
          </p>
          <p>
            In my blog, i've taken account of places, restaurants, monuments
            etc, that i've genuinely enjoyed and would definitely recommend as
            they are worth the visit!
          </p>
        </div>
      </div>
      <h2>Places to Visit in Milan </h2>
      <Posts data={data} />
    </div>
  );
}

export default Milan;
