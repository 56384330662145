import "./posts.css";
import Card from "../card/Card";
import HomePageCards from "../homepage-cards/HomePageCards";

export default function Posts(props) {
  const data = props.data;
  // return (
  //   <div className="posts">
  //     {data
  //       ? data?.map((details) => (
  //           <Card
  //             image={details.image}
  //             title={details.title}
  //             link={details.link}
  //           />
  //         ))
  //       : null}
  //   </div>
  // );

  return (
    <div className="posts">
      {data
        ? data.map((details) => (
            <HomePageCards
              image={details.image}
              title={details.title}
              link={details.link}
              description={details.description}
            />
          ))
        : null}
    </div>
  );
}
