import { Link } from "react-router-dom";
import logo from "../../images/logo-bg.png";
import "./topbar.css";

export default function Topbar() {
  return (
    <>
      <div className="top"></div>
      <div className="topBar">
        <div className="topLeft">
          <img className="topImg" src={logo} alt="Travelling with Tan" />
        </div>
        <div className="topCenter">
          <ul className="topList">
            <li className="topListItem">
              <Link className="link" to="/">
                HOME
              </Link>
            </li>
            <li className="topListItem">
              <Link className="link" to="/about">
                ABOUT
              </Link>
            </li>
            <li className="topListItem">
              <Link className="link" to="/contact">
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
        <div className="topRight"></div>
      </div>
    </>
  );
}
