import React from "react";
import "./bellagio.css";

function Bellagio() {
  return (
    <div className="page-container backgroundBellagio">
      <br />
      <br />
      <div className="page-header">
        <h1>BELLAGIO</h1>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1685164345/IMG_20220510_114116_krbrbf.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Via Giuseppe Garibaldi</p>
        </div>
        <div className="page-text">
          <p>
            On our arrival, my sister and I instantly decided to rent an
            electric bike (€ 10 per person, per hour) and thought it would be
            fun to cycle around the area. I'm glad to say it was.
          </p>
          <p>
            We set off up the slope, with no destination in mind. On our way, we
            found ourselves at the
            <span className="pacifico-text">Via Giuseppe Garibaldi</span>, and
            it looked even better in person. There were not a lot of tourists
            and the area was relatively easy to go through. We passed by many
            pristine narrow alleys on the way, and then went down hill, ending
            up in <span className="pacifico-text">Punta Spartivento</span>.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1685164344/IMG_20220510_115115_mzj5dz.jpg"
              alt="My Image"
            />
          </div>
          <p style={{ fontWeight: "bold" }}>Punta Spartivento</p>
        </div>
        <div className="page-text">
          <p>
            It was definitely worth going there as we not only got a majestic
            view of the mountains and the lake itself, but its serene and calm
            ambiance was also brought about by the small garden sitting right
            beside it.
          </p>
          <p>
            Next we rode off wherever the road took us and I can say this was
            honestly my most treasured part of the trip. The roads took us to
            points of awe-inspiring beauty - full and eye opening views. It was
            also quite dangerous as we encountered multiple vehicle attacks from
            the side, but it was worth the risk. We eventually took the help of
            google maps to direct us back to the bike shop as we had ended up in
            the middle of nowhere, unknowingly.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1685164354/IMG_20220510_123156_d8mopv.jpg"
              alt="My Image"
            />
          </div>
        </div>
        <div className="page-text">
          <p>
            Dehydrated and worn down from the cycling, we headed towards a
            gelato shop-
            <span className="pacifico-text">Gelateria Artigianale </span> and
            got a fro-yo that I would gladly go all the way back for.
          </p>
          <p>
            With our growing appetite, we headed to
            <span className="pacifico-text">La Lanterna </span>for some freshly
            made pasta.The outdoor seating along the walls of the alley was what
            attracted me to the place.
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="page-image">
          <div className="image">
            <img
              src="https://res.cloudinary.com/dl695mmqm/image/upload/v1685164345/IMG_20220510_134409_kykl1i.jpg"
              alt="My Image"
            />
          </div>
        </div>
        <div className="page-text">
          <p>
            At the start of our arriving food coma, we strolled the alleys and
            went into little shops that sold jewelry, a variety of clothes,
            hats, key chains, kitchen utensils and it is on the pricey side but
            nonetheless, we still enjoyed just window shopping.
          </p>
          <p>
            One store that did strike my attention was this one-
            <span className="pacifico-text">Lavanda del Lago. </span>It had
            everything lavender. Its exterior was decorated gracefully with
            overhanging flowers and a blast of the purplish hue.
          </p>
          <p>I left the store with a overpriced candle.</p>
          <p>
            I also definitely recommend going to
            <span className="pacifico-text">Pescallo (fishing village)</span>
            that we missed due to the impending food coma. Here you can choose
            to go kayaking or set sail on one of the boats.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Bellagio;
