import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Topbar from "./components/topbar/Topbar";
import Homepage from "./pages/homepage/Homepage";
import About from "./components/about/About";
import Footer from "./components/footer/Footer";
import Contact from "./components/contact/Contact";
import Duomo from "./components/sub-places/duomo/Duomo";
import Brera from "./components/sub-places/brera/Brera";
import Chinatown from "./components/sub-places/chinatown/Chinatown";
import Naviglio from "./components/sub-places/naviglio/Naviglio";
import PortRomana from "./components/sub-places/porta-romana/PortRomana";
import PrettiestPlaceInMillan from "./components/sub-places/prettiest-place-in-milan/PrettiestPlaceInMilan";
import Bellagio from "./components/sub-places/bellagio/Bellagio";
import Varenna from "./components/sub-places/varenna/Varenna";
import Milan from "./components/places/milan/Milan";
import LakeComo from "./components/places/lake-como/LakeComo";

function App() {
  return (
    <Router>
      <Topbar />
      <Switch>
        <Route exact path="/">
          <Homepage />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route path="/duomo">
          <Duomo />
        </Route>
        <Route path="/brera">
          <Brera />
        </Route>
        <Route path="/chinatown">
          <Chinatown />
        </Route>
        <Route path="/naviglio">
          <Naviglio />
        </Route>
        <Route path="/port-romana">
          <PortRomana />
        </Route>
        <Route path="/prettiest-place-in-milan">
          <PrettiestPlaceInMillan />
        </Route>
        <Route path="/bellagio">
          <Bellagio />
        </Route>
        <Route path="/varenna">
          <Varenna />
        </Route>
        <Route path="/milan">
          <Milan />
        </Route>
        <Route path="/lake-como">
          <LakeComo />
        </Route>
      </Switch>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
